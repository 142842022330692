var exports = {};
var KEBAB_REGEX = /[A-Z]/g;

var hash = function (str) {
  var h = 5381,
      i = str.length;

  while (i) h = h * 33 ^ str.charCodeAt(--i);

  return "_" + (h >>> 0).toString(36);
};

exports.create = function (config) {
  config = config || {};
  var assign = config.assign || Object.assign;
  var client = typeof window === "object"; // Check if we are really in browser environment.

  var renderer = assign({
    raw: "",
    pfx: "_",
    client: client,
    assign: assign,
    stringify: JSON.stringify,
    kebab: function (prop) {
      return prop.replace(KEBAB_REGEX, "-$&").toLowerCase();
    },
    decl: function (key, value) {
      key = renderer.kebab(key);
      return key + ":" + value + ";";
    },
    hash: function (obj) {
      return hash(renderer.stringify(obj));
    },
    selector: function (parent, selector) {
      return parent + (selector[0] === ":" ? "" : " ") + selector;
    },
    putRaw: function (rawCssRule) {
      renderer.raw += rawCssRule;
    }
  }, config);

  if (renderer.client) {
    if (!renderer.sh) document.head.appendChild(renderer.sh = document.createElement("style"));

    renderer.putRaw = function (rawCssRule) {
      // .insertRule() is faster than .appendChild(), that's why we use it in PROD.
      // But CSS injected using .insertRule() is not displayed in Chrome Devtools,
      // that's why we use .appendChild in DEV.
      {
        var sheet = renderer.sh.sheet; // Unknown pseudo-selectors will throw, this try/catch swallows all errors.

        try {
          sheet.insertRule(rawCssRule, sheet.cssRules.length); // eslint-disable-next-line no-empty
        } catch (error) {}
      }
    };
  }

  renderer.put = function (selector, decls, atrule) {
    var str = "";
    var prop, value;
    var postponed = [];

    for (prop in decls) {
      value = decls[prop];

      if (value instanceof Object && !(value instanceof Array)) {
        postponed.push(prop);
      } else {
        str += renderer.decl(prop, value, selector, atrule);
      }
    }

    if (str) {
      str = selector + "{" + str + "}";
      renderer.putRaw(atrule ? atrule + "{" + str + "}" : str);
    }

    for (var i = 0; i < postponed.length; i++) {
      prop = postponed[i];

      if (prop[0] === "@" && prop !== "@font-face") {
        renderer.putAt(selector, decls[prop], prop);
      } else {
        renderer.put(renderer.selector(selector, prop), decls[prop], atrule);
      }
    }
  };

  renderer.putAt = renderer.put;
  return renderer;
};

export default exports;
export const create = exports.create;